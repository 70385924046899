<template>
  <v-container fluid class="text-center">
    <v-row class="mt-0 mx-1">
      <v-col cols="12">
        <v-card>
          <v-toolbar dense dark color="secondary" elevation="2">
            <v-toolbar-title>
              Mapa
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-switch
              v-model="switchVolumen"
              :label="`¿Tienen Volumen?`"
              class="ma-0 pa-0 mt-6 mr-4 caption"
            ></v-switch>
            Plantas: {{ catalogues.plantas.length }}            
            <v-select v-model="control.planta" :items="catalogues.plantas" item-value="id" :item-text="item => item.codigo +' - '+ item.nombre" label="Planta seleccionada"
            solo dense class="ml-2 caption" :disabled="loading" light hide-details style="max-width: 300px;"> 
              <template #selection="{ item }">
                <span class="caption"> {{ item.codigo }} - {{item.nombre }} </span>
              </template>
              <template #prepend-inner>
                <v-img
                  :lazy-src="iconPlant.url"
                  max-height="25"
                  max-width="24"
                  :src="iconPlant.url"
                  class="mr-2"
                ></v-img>
              </template>
            </v-select>
          </v-toolbar>
          <v-card-text class="pa-1" :style="'height:'+ window.height +'px'">            
            <gmap-map ref="gmap" 
              :center="center"
              :zoom="zoom"              
              style="width: 100%; height: 100%"
              @click="clickMap"
              @idle="changeIdle">
              <gmap-marker                  
                  v-for="(m, index) in catalogues.plantas"
                  :key="index"
                  :position="m.posicion"
                  :draggable="false"
                  :zoom="actualZoom"
                  :label= "(actualZoom>9 && m.gemela<1 || actualZoom>9 )?{
                    text: m.nombre,
                    color: `${(!switchVolumen)?(control.planta==m.id)?'#A0240E':'#000':(control.planta==m.id)?'#A0240E':'#0D47A1'}`,
                    fontSize:'8px',
                    fontWeight:'bold',
                    className: 'shadowLabel'
                  }:null"
                  :icon="
                    (control.planta==m.id)?                      
                      (actualZoom>10)?iconPlantNormalSelected:iconPlantSmallSelected
                    :(switchVolumen)?
                      (actualZoom>10)?iconPlantNormal:iconPlantSmall:
                      (actualZoom>10)?iconPlantNormalQ:iconPlantSmallQ"
                  @click="clickPlant(m.id)">
              </gmap-marker>
            </gmap-map>            
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>        
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'
import services from '@/utils/services'

export default {
  name: 'shakermap',
  data: () => ({
    switchVolumen:false,
    loading: false, 
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    center: {lat:25.67915,lng:-100.31721}, // Monterrey
    zoom:11,
    window: {
      width: 0,
      height: 0
    },
    catalogues: {
      plantas: []
    },
    control: {
      planta: null,      
    },
    items: [],
    iconPlantSmall:'',
    iconPlantNormal:'',
    iconPlantSmallSelected:'',
    iconPlantNormalSelected:'',
    iconPlantSmallQ:'',
    iconPlantNormalQ:'',
    iconPlant:'',
    actualZoom:11,    
  }),  
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  mounted () {
    this.refresh(this.$store.state.general.company)
    
    this.$refs.gmap.$mapPromise.then((map) => {
      map.panTo({lat: 19.43261, lng: -99.13321}) //Move to Ciudad de Mexico
    }) 
    
    this.$gmapApiPromiseLazy().then(() => {
      this.iconPlantSmall = {
         url: require('@/assets/circlemarkersel.gif'),
        size: new google.maps.Size(24,24),
        scaledSize: new google.maps.Size(24,26),
        labelOrigin: new google.maps.Point(12,26)
        /* url: require('@/assets/circlemarkersmall.png'),
        size: new google.maps.Size(24, 24),
        labelOrigin: new google.maps.Point(12, 26) */
      }
      this.iconPlantNormal = {
        url: require('@/assets/circlemarkersel.gif'),
        size: new google.maps.Size(32,32),
        scaledSize: new google.maps.Size(32,34),
        labelOrigin: new google.maps.Point(16,34)
        /* url: require('@/assets/circlemarker.png'),
        size: new google.maps.Size(32,32),
        labelOrigin: new google.maps.Point(16, 34) */
      },
      this.iconPlantSmallQ = {        
        url: require('@/assets/circlemarkersmall.png'),
        size: new google.maps.Size(24, 24),
        labelOrigin: new google.maps.Point(12, 26)
      }
      this.iconPlantNormalQ = {        
        url: require('@/assets/circlemarker.png'),
        size: new google.maps.Size(32,32),
        labelOrigin: new google.maps.Point(16, 34)
      },
      this.iconPlantNormalSelected = {
        url: require('@/assets/circlemarkerselr.png'),
        size: new google.maps.Size(32,32),
        labelOrigin: new google.maps.Point(16, 34)
      }
      this.iconPlantSmallSelected = {
        url: require('@/assets/circlemarkersmallselr.png'),
        size: new google.maps.Size(24, 24),
        labelOrigin: new google.maps.Point(12, 26)
      }
      this.iconPlant = {
        url: require('@/assets/plantIcon.png'),
        size: new google.maps.Size(24,25),
        labelOrigin: new google.maps.Point(12, 25)
      }
    })
  },
  computed: {
    google: gmapApi,
    company() {
      return this.$store.state.general.company
    },
    plant() {
      return this.control.planta
    }
  },
  watch: {
    company: function (val) {
      this.loading = true
      this.clear()
      setTimeout(() => {
        this.refresh(val)
      }, 500);
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight-150;
    },
    refresh(id) {
      if (id) {
        this.loading = true
        this.axios.get(services.routes.user + '/plant' + '?id=' + this.$store.state.general.company)
          .then(response => {            
            this.catalogues.plantas = response.data.data
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    clear() {
      this.catalogues = {
        plantas: []
      }
      this.control = {
        planta: null
      }
      this.items = []      
    },
    changeIdle(){
      this.center = this.$refs.gmap.$mapObject.getCenter()
      this.actualZoom =  this.$refs.gmap.$mapObject.getZoom()
    },
    setMapCenter(){
      this.center= (this.catalogues.plantas.find(element => element.id==this.control.planta)).posicion;
    },
    clickMap(){
      this.control = {
        planta: null
      }
    },
    clickPlant(id){
      this.control = {
        planta: id
      }
    }
  },
}
</script>

<style>
  .text-normal {
    text-transform: none !important;
  }
  .shadowLabel {    
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;    
  }  
</style>
