var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"text-center",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-0 mx-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"secondary","elevation":"2"}},[_c('v-toolbar-title',[_vm._v(" Mapa ")]),_c('v-spacer'),_c('v-switch',{staticClass:"ma-0 pa-0 mt-6 mr-4 caption",attrs:{"label":"¿Tienen Volumen?"},model:{value:(_vm.switchVolumen),callback:function ($$v) {_vm.switchVolumen=$$v},expression:"switchVolumen"}}),_vm._v(" Plantas: "+_vm._s(_vm.catalogues.plantas.length)+" "),_c('v-select',{staticClass:"ml-2 caption",staticStyle:{"max-width":"300px"},attrs:{"items":_vm.catalogues.plantas,"item-value":"id","item-text":function (item) { return item.codigo +' - '+ item.nombre; },"label":"Planta seleccionada","solo":"","dense":"","disabled":_vm.loading,"light":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.codigo)+" - "+_vm._s(item.nombre)+" ")])]}},{key:"prepend-inner",fn:function(){return [_c('v-img',{staticClass:"mr-2",attrs:{"lazy-src":_vm.iconPlant.url,"max-height":"25","max-width":"24","src":_vm.iconPlant.url}})]},proxy:true}]),model:{value:(_vm.control.planta),callback:function ($$v) {_vm.$set(_vm.control, "planta", $$v)},expression:"control.planta"}})],1),_c('v-card-text',{staticClass:"pa-1",style:('height:'+ _vm.window.height +'px')},[_c('gmap-map',{ref:"gmap",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.center,"zoom":_vm.zoom},on:{"click":_vm.clickMap,"idle":_vm.changeIdle}},_vm._l((_vm.catalogues.plantas),function(m,index){return _c('gmap-marker',{key:index,attrs:{"position":m.posicion,"draggable":false,"zoom":_vm.actualZoom,"label":(_vm.actualZoom>9 && m.gemela<1 || _vm.actualZoom>9 )?{
                  text: m.nombre,
                  color: ("" + ((!_vm.switchVolumen)?(_vm.control.planta==m.id)?'#A0240E':'#000':(_vm.control.planta==m.id)?'#A0240E':'#0D47A1')),
                  fontSize:'8px',
                  fontWeight:'bold',
                  className: 'shadowLabel'
                }:null,"icon":(_vm.control.planta==m.id)?                      
                    (_vm.actualZoom>10)?_vm.iconPlantNormalSelected:_vm.iconPlantSmallSelected
                  :(_vm.switchVolumen)?
                    (_vm.actualZoom>10)?_vm.iconPlantNormal:_vm.iconPlantSmall:
                    (_vm.actualZoom>10)?_vm.iconPlantNormalQ:_vm.iconPlantSmallQ},on:{"click":function($event){return _vm.clickPlant(m.id)}}})}),1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"color":"success","timeout":2500,"bottom":"","right":""},model:{value:(_vm.success),callback:function ($$v) {_vm.success=$$v},expression:"success"}},[_vm._v(" "+_vm._s(_vm.msgSuccess)+" ")]),_c('v-snackbar',{attrs:{"color":"error","timeout":2500,"bottom":"","right":""},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(" "+_vm._s(_vm.msgError)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }